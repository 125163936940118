"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

/* -------------------------------------------------------------------------- */

/*                              FULLPAGE SETTINGS                             */

/* -------------------------------------------------------------------------- */
// Function to make sure playground only runs once
function once(fn, context) {
  var result;
  return function () {
    if (fn) {
      result = fn.apply(context || this, arguments);
      fn = null;
    }

    return result;
  };
}

var oncePlayground = once(playGround);
new fullpage("#fullpage", {
  scrollingSpeed: 900,
  navigation: true,
  css3: true,
  // slidesNavigation: true,
  responsiveHeight: 330,
  navigationPosition: "right",
  controlArrows: false,
  recordHistory: false,
  licenseKey: "D679BE34-17A44724-B98D9F26-148A2CF3",
  anchors: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13"],
  sectionsColor: ["#f08481", "#e67d7a", "#dd7774", "#ffffff", "#d4706d", "#ffffff", "#ca6a67", "#ffffff", "#c16360", "#b85d5a", "#af5754", "#a6504e", "#9d4a48" // "#944442",
  // "#8b3e3c",
  // "#823836",
  ],
  // Prevent auto-scroll on playground and when the modal is open
  normalScrollElements: "#matter-wrap, .popbox",
  afterLoad: function afterLoad(origin, destination, direction) {
    // var loadedSection = this;
    // INITIALISE BLOCK PLAYGROUND ON PAGE 5
    if (destination.anchor == "5") {
      oncePlayground(); // Run Playground but only once
    }
  }
});
/* -------------------------------------------------------------------------- */

/*                    FIRST SLIDE - WELCOME/PLAY/FULLSCREEN                   */

/* -------------------------------------------------------------------------- */

var audioPlayerOne = document.getElementById("audio-player-1"); // FRONTPAGE FULLSCREEN BUTTON AND PLAYBACK START

document.getElementById("start-button").addEventListener("click", function () {
  if (screenfull.isEnabled) {
    screenfull.request();
    audioPlayerOne.play();
  }
});
/* -------------------------------------------------------------------------- */

/*                    SKIP TO NEXT SLIDE AFTER PLAYBACK ENDED                 */

/* -------------------------------------------------------------------------- */

var autoSkip = document.querySelectorAll(".auto-skip");

function moveDown(e) {
  fullpage_api.moveSectionDown();
}

autoSkip.forEach(function (item) {
  item.addEventListener("ended", moveDown, false);
});
/* -------------------------------------------------------------------------- */

/*                              NEXT SLIDE BUTTON                             */

/* -------------------------------------------------------------------------- */

var nextPlease = document.querySelectorAll(".next-slide-button");
nextPlease.forEach(function (item) {
  item.addEventListener("click", function () {
    fullpage_api.moveSectionDown();
  });
});
/* -------------------------------------------------------------------------- */

/*                          MODAL - LIGHTBOX - POPBOX                         */

/* -------------------------------------------------------------------------- */

var popbox = new Popbox({
  blur: true,
  overlay: true
});
/* -------------------------------------------------------------------------- */

/*                ON ANIMAL SECTION JUMP BACK WHEN VIDEO ENDED                */

/* -------------------------------------------------------------------------- */

var animalVids = document.querySelectorAll(".animal-vid");

function moveBack(e) {
  fullpage_api.silentMoveTo("2", 0);
}

animalVids.forEach(function (item) {
  item.addEventListener("ended", moveBack, false);
});
/* -------------------------------------------------------------------------- */

/*                      PAUSE OR PLAY ALL VIDEO ON CLICK                      */

/* -------------------------------------------------------------------------- */

var allVids = document.querySelectorAll("video");
allVids.forEach(function (item) {
  item.addEventListener("click", function () {
    if (this.paused == false) {
      this.pause();
    } else {
      this.play();
    }
  });
});
/* -------------------------------------------------------------------------- */

/*                     ADD ANIMATIONS WHEN ELEMENT IN VIEW                    */

/* -------------------------------------------------------------------------- */

aaosInit({
  root: "document.body",
  rootMargin: "0px",
  threshold: 0.1
});

function aaosInit(anchor) {
  var aaosClassName = "as";
  var options;
  if (anchor && _typeof(anchor) == "object") options = anchor;else options = {
    root: document.querySelector(anchor || document.body),
    rootMargin: "0px",
    threshold: 0.9
  };
  if (options && options.aaosClassName) aaosClassName = options.aaosClassName;
  if (options && options.root && typeof options.root == "string") options.root = document.querySelector(options.root);
  var observer = new IntersectionObserver(callback, options);
  var targets = document.getElementsByClassName(aaosClassName);

  for (var i = 0; i < targets.length; i++) {
    observer.observe(targets[i]);
  }

  function callback(entries, observer) {
    entries.forEach(function (entry) {
      if (entry.isIntersecting) replaceClass(entry.target);
    });
  }

  function replaceClass(target) {
    var classList = target.classList;

    for (var i = 0; i < classList.length; i++) {
      if (classList[i].startsWith("s_animate")) {
        var klass = classList[i];
        target.classList.remove(classList[i]);
        target.classList.add(klass.substring(2));
        target.classList.remove(aaosClassName);
      }
    }
  }
}
/* -------------------------------------------------------------------------- */

/*                    SET VOLUME FOR APPLAUSO-METER                           */

/* -------------------------------------------------------------------------- */


function SetVolume(val) {
  var applausePlayer = document.getElementById("applause-audio");
  applausePlayer.volume = val / 100;
}

SetVolume(0);
/* -------------------------------------------------------------------------- */

/*                    END FULLSCREEN ON LAST PAGE                             */

/* -------------------------------------------------------------------------- */

document.getElementById("end-fullscreen-button").addEventListener("click", function () {
  if (screenfull.isEnabled) {
    screenfull.exit();
  }
});