"use strict";

function playGround() {
  var Engine = Matter.Engine;
  var Render = Matter.Render;
  var Bodies = Matter.Bodies;
  var Common = Matter.Common;
  var Composites = Matter.Composites;
  var Mouse = Matter.Mouse;
  var MouseConstraint = Matter.MouseConstraint;
  var World = Matter.World;
  var Events = Matter.Events;
  /* -------------------------------------------------------------------------- */

  /*                                Setup Canvas                                */

  /* -------------------------------------------------------------------------- */

  var canvas = document.getElementById("matter-wrap");
  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;
  window.addEventListener("resize", function () {
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
  });

  function percentX(percent) {
    return Math.round(percent / 100 * canvas.width);
  }

  function percentY(percent) {
    return Math.round(percent / 100 * canvas.height);
  }
  /* -------------------------------------------------------------------------- */

  /*                                Setup engine                                */

  /* -------------------------------------------------------------------------- */


  var engine = Engine.create();
  Matter.Runner.run(engine);
  /* -------------------------------------------------------------------------- */

  /*                                Setup render                                */

  /* -------------------------------------------------------------------------- */

  var render = Render.create({
    element: canvas,
    engine: engine,
    options: {
      width: percentX(100),
      height: percentY(100),
      wireframes: false,
      background: "#d4706d"
    }
  });
  Render.run(render);
  Render.run(render, engine);
  engine.positionIterations = 100;
  engine.velocityIterations = 100;
  /* get world from engine ---------------------------------------------------- */

  var world = engine.world;
  /* -------------------------------------------------------------------------- */

  /*                                FLOOR & WALLS                               */

  /* -------------------------------------------------------------------------- */

  var floorTop = Bodies.rectangle(percentX(50), 0, percentX(800), 5, {
    isStatic: true,
    render: {
      fillStyle: "#d4706d"
    }
  });
  var floorBottom = Bodies.rectangle(percentX(50), percentY(100), percentX(800), 5, {
    isStatic: true,
    render: {
      fillStyle: "#c96360"
    }
  });
  var floorRight = Bodies.rectangle(percentX(100), 0, 10, percentY(800), {
    isStatic: true,
    render: {
      fillStyle: "#d4706d"
    }
  });
  var floorLeft = Bodies.rectangle(0, 0, 10, percentY(800), {
    isStatic: true,
    render: {
      fillStyle: "#d4706d"
    }
  });
  World.add(world, [floorTop, floorBottom, floorLeft, floorRight]);
  /* -------------------------------------------------------------------------- */

  /*                              Boxes & Materials                             */

  /* -------------------------------------------------------------------------- */
  // var stack = Composites.stack(percentX(40), percentY(10), 3, 3, 0, 0, function (x, y) {
  // 	var sides = Math.round(Common.random(3, 4));
  // 	var color = {
  // 		render: {
  // 			fillStyle: Common.choose(["#0B0835", "#3F8A8C", "#0C5679", "#E5340B", "#FFB451", "#FFE7BD"]),
  // 		},
  // 	};
  // 	var randomNumber = Math.random();
  // 	if (randomNumber < 0.5) {
  // 		return Bodies.rectangle(x, y, Common.random(50, 100), Common.random(50, 100), color);
  // 	} else {
  // 		return Bodies.polygon(x, y, sides, Common.random(50, 75), color);
  // 	}
  // });

  var vertices = [{
    x: 0,
    y: 0
  }, {
    x: 345,
    y: 0
  }, {
    x: 180,
    y: 400
  }];
  var tent = Bodies.fromVertices(percentX(10), percentY(10), vertices, {
    render: {
      sprite: {
        texture: "./img/tent_block.png"
      }
    }
  });
  Matter.Body.rotate(tent, 135);
  World.add(world, tent);
  var trianglevertice = [{
    x: 0,
    y: 0
  }, {
    x: 117,
    y: 0
  }, {
    x: 60,
    y: 95
  }];
  var stack = Composites.stack(percentX(40), percentY(10), 3, 3, 0, 0, function (x, y) {
    var rando = Math.round(Math.random() * 100);

    if (rando <= 25) {
      return Bodies.fromVertices(x, y, trianglevertice, {
        render: {
          sprite: {
            texture: "./img/triangle.png"
          }
        }
      });
    } else if (rando <= 50) {
      return Bodies.circle(x, y, 46, {
        render: {
          sprite: {
            texture: "./img/ball.png"
          }
        }
      });
    } else if (rando <= 75) {
      return Bodies.rectangle(x, y, 94, 94, {
        render: {
          sprite: {
            texture: "./img/box.png"
          }
        }
      });
    } else if (rando <= 100) {
      return Bodies.rectangle(x, y, 300, 100, {
        render: {
          sprite: {
            texture: "./img/rectangle.png"
          }
        }
      });
    }
  });
  World.add(world, stack);
  /* -------------------------------------------------------------------------- */

  /*                               Mouse Settings                               */

  /* -------------------------------------------------------------------------- */

  var mouse = Mouse.create(render.canvas);
  var mouseConstraint = MouseConstraint.create(engine, {
    mouse: mouse,
    constraint: {
      stiffness: 0.2,
      render: {
        visible: false
      }
    }
  });
  World.add(world, mouseConstraint); // keep the mouse in sync with rendering

  render.mouse = mouse;
  mouseConstraint.mouse.element.removeEventListener("DOMMouseScroll", mouseConstraint.mouse.mousewheel);
  mouseConstraint.mouse.element.removeEventListener("mousewheel", mouseConstraint.mouse.mousewheel);
  /* -------------------------------------------------------------------------- */

  /*                             Add Boxes on MouseClick                        */

  /* -------------------------------------------------------------------------- */

  Events.on(mouseConstraint, "mousedown", function (event) {
    var source = event.source;
    var mouse = event.mouse;

    if (source.body === null) {
      // Choose color from presets
      // var colorOption = {
      // 	render: {
      // 		fillStyle: Common.choose(["#0B0835", "#3F8A8C", "#0C5679", "#E5340B", "#FFB451", "#FFE7BD"]),
      // 	},
      // };
      var box = "";
      var rando = Math.round(Math.random() * 100);

      if (rando <= 25) {
        box = Bodies.circle(mouse.position.x, mouse.position.y, 46, {
          render: {
            sprite: {
              texture: "./img/ball.png"
            }
          }
        });
      } else if (rando <= 50) {
        box = Bodies.fromVertices(mouse.position.x, mouse.position.y, trianglevertice, {
          render: {
            sprite: {
              texture: "./img/triangle.png"
            }
          }
        });
      } else if (rando <= 75) {
        box = Bodies.rectangle(mouse.position.x, mouse.position.y, 94, 94, {
          render: {
            sprite: {
              texture: "./img/box.png"
            }
          }
        });
      } else if (rando <= 100) {
        box = Bodies.rectangle(mouse.position.x, mouse.position.y, 300, 100, {
          render: {
            sprite: {
              texture: "./img/rectangle.png"
            }
          }
        });
      }

      World.add(world, box);
    }
  });
}